

import { Vue, Component } from 'vue-property-decorator'
import { DetailInfo, Info } from '@/types/projectFile'

@Component
export default class extends Vue {
  private searchInfo: DetailInfo = {
    fileName: '',
    timeNode: '',
    fileTypeId: '',
    dateRange: []
  }

  private loading = false
  private tableData = []
  private total = 0
  private page = 1
  private size = 10
  private typeList = []
  private nodeList = [
    {
      label: '项目进场前',
      value: '1'
    },
    {
      label: '施工作业中',
      value: '2'
    },
    {
      label: '验收完成',
      value: '3'
    },
    {
      label: '审计定案',
      value: '4'
    }
  ]

  formData = {
  }

  get projectId () {
    return this.$route.params.projectId as string
  }

  created () {
    this.loadData()
  }

  onSearch () {
    this.page = 1
    this.loadData()
  }

  loadData () {
    this.loading = true
    const time = this.searchInfo.dateRange && this.searchInfo.dateRange.length > 0 ? {
      startTime: this.searchInfo.dateRange[0] || '',
      endTime: this.searchInfo.dateRange[1] || ''
    } : {}
    this.$axios.get(this.$apis.projectFile.selectYhProjectFilesByPage,
      {
        projectId: this.projectId,
        fileName: this.searchInfo.fileName,
        timeNode: this.searchInfo.timeNode,
        fileTypeId: this.searchInfo.fileTypeId,
        ...time,
        page: this.page,
        size: this.size
      }).then(res => {
      this.tableData = res.list || []
      this.total = res.total || 0
    }).finally(() => {
      this.loading = false
    })
  }

  getTypeList () {
    this.searchInfo.fileTypeId = ''
    this.$axios.get(this.$apis.projectFile.selectYhProjectFileTypesByList, {
      timeNode: this.searchInfo.timeNode
    }).then(res => {
      this.typeList = res || []
    })
  }

  // 下载
  onDownload (row: Info) {
    window.location.href = `${row.fileList[0].filePrefix + row.fileList[0].fileUrl}?attname=${row.fileList[0].fileName}`
  }

  onDelete (fileId: string) {
    this.$confirm('确认删除吗, 是否继续?', '提示', {
      confirmButtonText: '确定',
      cancelButtonText: '取消',
      type: 'warning'
    }).then(() => {
      this.$axios.post(this.$apis.projectFile.deleteYhProjectFiles, { fileId }).then(() => {
        this.$message.success('删除成功')
        this.onSearch()
      })
    })
  }
}
